import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Welcome to SObespoke"
          subTitle="SObespoke is a boutique interior design studio, designing beautiful and enduring interiors."
        />
        <BasicTextModule
          title="An Interior Designer with more than 10 years' industry experience"
          content="I provide a pragmatic, down to earth approach, whilst committing to designing spaces that truly reflect my clients and the way they use their spaces day to day."
          link="/projects"
          linkText="View Portfolio"
        />
        <Features
          title="Featured Projects from SObespoke."
          introduction="We specialise in: bespoke colour schemes; curtains, blinds and shutters; procurement and styling of unique spaces; renovations or new builds."
        />
      </Layout>
    </>
  )
}

export default Index
